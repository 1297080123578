import React from 'react'
import Layout from '../layouts'
import style from '../layouts/style.module.sass'
import LinkTop from '../components/LinkTop'
import Helmet from 'react-helmet'

const ProfilePage = () => (
  <Layout>
    <Helmet
      title="Profile | webdevelopment.jp"
      meta={[
        {
          name: 'description',
          content: 'webdevelopment.jpのプロフィールページ',
        },
      ]}
    />
    <h1>Profile</h1>

    <p>
      広告・Web制作会社、ECサイト運営会社、Webサービス開発会社などで制作・開発に携わった経験豊富なスタッフが問題を解決します。
    </p>

    <h2>Skill Set</h2>
    <div className={style.skillListWrapper}>
      <section className={style.skillListSection}>
        <h3>Design</h3>
        <ul>
          <li>Photoshop</li>
          <li>Illustrator</li>
          <li>Premiere</li>
          <li>InDesign</li>
          <li>AfterEffects</li>
        </ul>
      </section>
      <section className={style.skillListSection}>
        <h3>Web Development</h3>
        <ul>
          <li>HTML5</li>
          <li>CSS3</li>
          <li>Bootstrap</li>
          <li>SASS</li>
          <li>JavaScript</li>
          <li>React</li>
          <li>Redux</li>
          <li>Gatsby</li>
          <li>jQuery</li>
          <li>BABEL</li>
          <li>PHP</li>
          <li>WordPress</li>
          <li>gulp.js</li>
          <li>Git</li>
        </ul>
      </section>
      <section className={style.skillListSection}>
        <h3>Interactive Programming</h3>
        <ul>
          <li>Ableton Live</li>
          <li>Max</li>
          <li>PureData</li>
          <li>openFrameworks</li>
          <li>Arduino</li>
        </ul>
      </section>
    </div>

    <LinkTop scrollStepInPx="50" delayInMs="16.66" />
  </Layout>
)

export default ProfilePage
